<template>
  <div class="unit-event-selection mt-1">
    <v-select
      v-model="events"
      :options="eventOptions"
      :reduce="(option) => option.value"
      @input="selectedEvents"
      label="value"
      id="select"
      :placeholder="$t('unitEvent.filterEvent')"
      multiple
      :clearable="false"
      clear-icon="bx bx-x-circle"
      class="mb-1"
    >
      <template #selected-option="{ icon, key }">
        <div class="selected-option">
          <b-avatar
            :src="icon"
            size="21"
            variant="light-primary"
            class="align-middle mr-50"
          />
          <span>{{ key }}</span>
        </div>
      </template>
      <template #option="{ icon, key }">
        <div class="selected-option">
          <b-avatar
            :src="icon"
            size="21"
            variant="light-primary"
            class="align-middle mr-50"
          />
          <span>{{ key }}</span>
        </div>
      </template>
    </v-select>
    <div
      class="time-line-outer list-scroll-event"
      ref="scrollContainer"
      @scroll="handleScroll"
    >
      <div class="table-responsive" v-if="loading == 0">
        <b-skeleton-table
          v-if="loading == 0"
          :rows="6"
          :columns="2"
          :table-props="{ bordered: true, striped: true }"
        />
      </div>
      <div v-if="loading == 1">
        <b-jumbotron class="mt-0" :lead="$t('unit.NoEventsTxt')"> </b-jumbotron>
      </div>
      <div v-if="loading == 2" class="list">
        <div
          class="time-linne-item"
          :class="event && event.display_card ? 'curserPointer' : ''"
          v-for="(event, key) in eventData"
          :key="key"
        >
          <div class="line-icon" @click="onEventClick(event)" v-if="event">
            <b-img :src="event.icon" v-if="event && event.icon"> </b-img>
          </div>

          <!-- <div v-html="event.display_card"></div> -->
          <div class="line-info-card" v-if="event">
            <div class="info-row" @click="onEventClick(event)">
              <div class="unit-col-1">
                <Timmer @click="onEventClick(event)" />
              </div>
              <div class="unit-col-2">
                <div class="line-date">
                  {{
                    `${
                      event.event_time
                        ? `${DateTimeConvert(
                            event.event_time,
                            constants.DATE_FORMAT
                          )}`
                        : " "
                    }`
                  }}
                </div>
                <div class="line-time">
                  {{
                    `${
                      event.event_time
                        ? `${DateTimeConvert(
                            event.event_time,
                            constants.TIME_FORMAT
                          )}`
                        : " "
                    }`
                  }}
                </div>
              </div>
              <div class="unit-col-3">
                <div class="line-information">
                  {{ event.event_name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BImg,
  BSkeletonTable,
  BJumbotron,
  BRow,
  BContainer,
  BCol,
  BAvatar
} from "bootstrap-vue";
import Timmer from "@/assets/images/unit-icon/timmer.svg";
import ParkedVeicle from "@/assets/images/unit-icon/parked-veicle.svg";
import ParkingIcon from "@/assets/images/unit-icon/parking-icon.svg";
import FuelPump from "@/assets/images/unit-icon/fuel-pump.svg";
import UnitService from "@/libs/api/unit-service";
import AddressIcon from "@/assets/images/unit-icon/address-icon.svg";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { setTimeout } from "core-js";
import constants from "@/utils/constants";
import moment from "moment-timezone";
export default {
  components: {
    Timmer,
    ParkedVeicle,
    ParkingIcon,
    FuelPump,
    BImg,
    AddressIcon,
    BSkeletonTable,
    BJumbotron,
    BRow,
    BContainer,
    BCol,
    vSelect,
    BAvatar
  },
  props: ["unitDateFilter", "selectedMenu", "playback"],
  watch: {
    unitDateFilter: {
      deep: true,
      handler(newOptions) {
        this.page = 1;
        this.page_size = 50;
        this.map.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            layer.remove();
          }
        });
        if (this.selectedMenu === "events") {
          this.playback.clearAllMarkers();
        }
        this.getUnitEvents();
      }
    },
    selectedMenu(val) {
      if (val === "events") {
        this.page = 1;
        this.page_size = 50;
        this.map.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            layer.remove();
          }
        });
        if (this.selectedMenu === "events") {
          this.playback.clearAllMarkers();
        }
        this.getUnitEvents();
      } else {
        this.playback.clearAllMarkers();
      }
    },
    $route(to, from) {
      if (to.name !== "unit-events") {
        this.playback.clearAllMarkers();
      }
    }
  },
  data() {
    return {
      totalEvents: 0,
      map: null,
      page: 1,
      page_size: 50,
      items: [],
      eventData: [],
      url: process.env.VUE_APP_ICON_URL,
      loading: 1,
      isReload: true,
      selectedOption: [],
      eventOptions: [],
      events: []
    };
  },
  mounted() {
    this.map = this.playback.getMap();
    this.getUnitEvents();
  },

  methods: {
    selectedEvents() {
      clearTimeout(this.toGetEvents);
      this.toGetEvents = setTimeout(() => {
        this.playback.clearAllMarkers();
        store.commit("mapLoader/SET_EVENT_POPUP_SHOW", false);
        this.page = 1;
        this.getUnitEvents();
      }, 2 * 1000);
    },

    socketDisconnect() {
      if (this.socket) {
        this.socket.disconnect();
        this.socket.unsubscribeEventWithFunction(
          `UM_${this.unitDateFilter.selectedUnit}`
        );
        this.socket = null;
      }
    },
    async getUnitEvents(isScroll) {
      const me = this;
      try {
        this.socketDisconnect();

        if (
          !this.unitDateFilter ||
          !this.unitDateFilter.selectedUnit ||
          !this.unitDateFilter.account_id ||
          !this.unitDateFilter.start_time ||
          !this.unitDateFilter.end_time ||
          this.unitDateFilter.start_time === "Invalid date" ||
          this.unitDateFilter.end_time === "Invalid date"
        ) {
          store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
          return;
        }
        let filters = [];
        if (
          this.unitDateFilter &&
          this.unitDateFilter.start_time &&
          this.unitDateFilter.end_time &&
          (this.unitDateFilter.start_time !== "Invalid date" ||
            this.unitDateFilter.end_time !== "Invalid date")
        ) {
          let account = localStorage.getItem("USER_ACCOUNT_DETAILS");
          account = account ? JSON.parse(account) : null;
          account =
            account && account.settings ? account.settings : constants.SETTINGS;
          const timeZone = account.timezone;
          filters.push({
            field: "time",
            start_value: moment
              .tz(this.unitDateFilter.start_time, timeZone || "utc")
              .startOf("day")
              .utc()
              .format(),
            end_value: this.unitDateFilter.end_time,
            operator: "between"
          });
        }
        if (this.events && this.events.length) {
          filters.push({
            field: "eventType",
            value: this.events,
            operator: "IN"
          });
        }
        if (
          !this.unitDateFilter.selectedUnit ||
          !this.unitDateFilter.account_id
        ) {
          return;
        }
        if (!isScroll) {
          this.loading = 0;
        }
        let requestData = {
          page: this.page,
          page_size: this.page_size,
          filters,
          unit_id: this.unitDateFilter.selectedUnit,
          account_id: this.unitDateFilter.account_id
        };
        this.isReload = false;
        let response = await new UnitService().getUnitEvents(requestData);
        if (response && response.data) {
          if (this.$route.name !== "unit-events") {
            if (this.map) {
              this.map.eachLayer((layer) => {
                if (layer instanceof L.Marker) {
                  layer.remove();
                }
              });
            }

            return;
          } else {
          }
          this.loading = 2;
          this.isReload = true;
          if (
            this.page === 1 &&
            response.data.events &&
            response.data.events.all_events &&
            response.data.events.all_events.length
          ) {
            this.eventOptions = response.data.events.all_events;
          }
          if (!isScroll) {
            this.eventData = response.data.events && response.data.events.list;
          } else {
            if (
              response.data.events &&
              response.data.events.list &&
              response.data.events.list.length
            ) {
              response.data.events.list.forEach((element) => {
                this.eventData.push(element);
              });
            }
          }

          response.data.events.list.forEach((element) => {
            if (
              element &&
              element.event_position &&
              element.event_position.lat &&
              element.event_position.lng &&
              element.display_card
            ) {
              element.marker = me.playback.addMarker({
                ...element.event_position,
                icon: element.icon,
                element: element
              });
            }
          });
          const mapLatLang = this.eventData.filter(
            (e) =>
              e &&
              e.event_position &&
              e.event_position.lat &&
              e.event_position.lng
          );

          const mapLatLang1 = mapLatLang.map((e) => [
            e.event_position.lat,
            e.event_position.lng
          ]);
          if (mapLatLang1 && mapLatLang1.length) {
            this.map.fitBounds(mapLatLang1);
          }

          this.totalEvents =
            (response.data.events &&
              response.data.events.pagination &&
              response.data.events.pagination.total_records) ||
            0;
          if (this.totalEvents === 0) {
            this.loading = 1;
            store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
          } else {
            store.commit("mapLoader/SET_IS_POPUP_SHOW", false);
          }
        } else if (response && response.error && response.error.message) {
          this.loading = 1;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.loading = 1;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    DateTimeConvert(date, format) {
      return this.formattedDateTime(date, format);
    },

    handleScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      const scrollOffset =
        scrollContainer.scrollHeight - scrollContainer.scrollTop - 1;
      const containerHeight = scrollContainer.clientHeight;
      if (scrollOffset > containerHeight && this.isReload) {
        if (this.totalEvents > this.page * this.page_size) {
          const me = this;
          this.$nextTick(() => {
            me.page++;
            me.getUnitEvents(true);
          });
        }
      }
    },
    onEventClick(element) {
      this.playback.highLightMarker(element.marker);
      store.commit("mapLoader/SET_EVENT_POPUP_SHOW", true);
      store.commit("mapLoader/SET_IS_POPUP_SHOW", false);
      this.playback.events.fire("pointClick", {
        message: {
          event_name: element.event_name,
          distance: 0,

          date: element.event_time,
          display_card: element.display_card,
          screen: "event"
        },
        is_live: false,
        screen: "event"
      });
    }
  },
  beforeDestroy() {
    this.playback.clearAllMarkers();
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.selected-option {
  display: flex;
  align-items: center;
}

.time-line-outer {
  // padding-left: 20px;
  // margin-right: 7px;
  // padding-bottom: 200px;
  position: relative;

  .time-linne-item {
    padding-left: 30px;
    padding-bottom: 15px;
    margin-left: 20px;
    margin-right: 7px;
    border-left: 4px solid var(--unit-time-line);
    position: relative;
    cursor: pointer;

    .line-icon {
      position: absolute;
      top: 13px;
      left: -20px;

      .tooltip-popover {
        display: block;
        max-width: 92%;
      }
    }

    .line-info-card {
      background-color: var(--unit-bg);
      padding: 8px 11px;
      border-radius: 8px;
      position: relative;
      cursor: pointer;

      .info-row {
        display: flex;
        align-items: center;

        .unit-col-1 {
          width: 22px;
          margin-right: 8px;
        }

        .unit-col-2 {
          width: calc(50% - 15px);
          padding-right: 8px;
          border-right: 2px solid var(--unit-divider);

          .line-time {
            font-size: 10px;
          }
        }

        .unit-col-3 {
          padding-left: 8px;
          width: calc(50% - 15px);
        }
      }
    }

    // &:last-child {
    //   border-left: 4px solid transparent;
    // }
  }
}

.dark-layout {
  .time-line-outer {
    .time-linne-item {
      .line-info-card {
        background-color: var(--unit-bg-dark);

        .tooltip-popover {
          background-color: var(--gray-500);
        }
      }
    }
  }
}

.list-scroll-event {
  max-height: calc(100vh - 425px) !important;
  min-height: calc(100vh - 425px) !important;
  overflow-y: auto;
  margin-top: 15px;
}

// .jumbotron {
//   margin-left: -16px;
// }

// @media screen and (max-width: 991px) {
//   .list-scroll-trip {
//     max-height: calc(100vh - 490px);
//     min-height: calc(100vh - 490px);
//   }
// }

@media screen and (max-width: 667px) {
  .list-scroll-event {
    max-height: calc(100vh - 415px);
    min-height: calc(100vh - 415px);
  }
}

.dash-border {
  border: 2px dashed #3388ff;
  // background-color: #3388ff4d;
  border-radius: 50%;
  box-shadow: 0px 0px 10px var(--primary);
  animation: bounceImg 3s 2;
  z-index: 30000 !important;
}

@keyframes bounceImg {
  0%,
  20%,
  50%,
  80%,
  100% {
    margin-top: 0;
  }

  40% {
    margin-top: -10px;
  }

  60% {
    margin-top: -5px;
  }
}

.unit-event-selection {
  .vs__selected-options .vs__selected {
    width: auto;
  }

  .vs--open {
    .vs__selected-options .vs__selected {
      opacity: 1 !important;
    }
  }

  .vs__clear {
    display: block !important;
  }

  .vs__dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
  }

  .vs__dropdown-toggle {
    overflow-y: auto;
    max-height: 80px;
  }
}

.list {
  // height: calc(100vh - 200px);
  // padding-left: 18px;
  // overflow-y: auto;
  margin-bottom: 50px !important;
  padding-bottom: 30vh;
}
</style>
