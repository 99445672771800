var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isShowMenu)?_c('div',{class:{
      'outer-info': true,
      'playback-only': _vm.isOnlyPlayBack,
      'min-outer': _vm.UnitInfo && !!_vm.UnitInfo.message_time,
      'max-outer': _vm.UnitInfo && !_vm.UnitInfo.message_time,
      'payer-added': _vm.$route.name == 'unit-history',
      'sidebar-close': !_vm.sidebarToggle,
      'sidebar-open': !!_vm.sidebarToggle
    }},[_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":_vm.clearUnitInfo}},[_vm._v(" × ")]),(_vm.unitInfoLoader && !_vm.UnitInfo)?_c('div',{staticClass:"unit-info-card",class:{ 'history-card': _vm.$route.name == 'unit-history' }},[_c('b-row',{staticStyle:{"width":"100%","max-height":"80%"}},[_c('b-col',{staticClass:"mt-1",attrs:{"md":"4","sm":"12"}},[_c('b-skeleton-img')],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"4","sm":"12"}},[_c('b-skeleton-img')],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"4","sm":"12"}},[_c('b-skeleton-img')],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"2","sm":"12"}},[_c('b-skeleton-img')],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"2","sm":"12"}},[_c('b-skeleton-img')],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"2","sm":"12"}},[_c('b-skeleton-img')],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"2","sm":"12"}},[_c('b-skeleton-img')],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"2","sm":"12"}},[_c('b-skeleton-img')],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"2","sm":"12"}},[_c('b-skeleton-img')],1)],1)],1):_vm._e(),(
        _vm.$route.name === 'unit-history' &&
        _vm.UnitInfo &&
        _vm.UnitInfo.name &&
        !_vm.unitInfoLoader
      )?_c('div',{staticClass:"unit-info-main"},[(_vm.UnitInfo)?_c('UnitPlayBack',{attrs:{"UnitInfo":_vm.UnitInfo,"isLiveSlide":_vm.isLiveSlide,"playback":_vm.playback,"isOnlyPlayBack":_vm.isOnlyPlayBack,"startPlayback":_vm.startPlayback}}):_vm._e()],1):_vm._e(),(_vm.UnitInfo && _vm.UnitInfo.name && !_vm.unitInfoLoader && !_vm.isOnlyPlayBack)?_c('div',{staticClass:"unit-info-card new-wall-comp",class:{
        'history-card': _vm.$route.name == 'unit-history'
      }},[_c('b-card',{staticClass:"cust-card-unit fst-card",attrs:{"no-body":""}},[_c('div',{staticClass:"card-data-ev"},[_c('b-avatar',{attrs:{"variant":"light-primary","pills":"","size":"65","src":_vm.UnitInfo.icon}}),_c('div',{staticClass:"unit-name-ev-block"},[_c('div',{staticClass:"unit-name-ev-text align-account-name"},[_vm._v(" "+_vm._s(_vm.UnitInfo.name)),_c('span',{staticClass:"unit-allocated-name text-primary"},[_vm._v(" ("+_vm._s(_vm.UnitInfo.allotted_account && _vm.UnitInfo.allotted_account.name)+")")])]),_c('Badge',{attrs:{"tags":_vm.UnitInfo.devices,"showTextInBadge":Number(8),"event":{ id: _vm.UnitInfo.name },"maxTagShow":Number(2),"variant":"light-primary"}})],1),(_vm.userModifiedPosition)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:({
              title: _vm.$t('unit.Recenter'),
              customClass: 'tooltip-card'
            }),expression:"{\n              title: $t('unit.Recenter'),\n              customClass: 'tooltip-card'\n            }",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle track-btn curserPointer",attrs:{"variant":"outline-primary"},on:{"click":_vm.recenterUnit}},[_c('feather-icon',{attrs:{"icon":"Navigation2Icon"}})],1):_vm._e()],1),_c('div',{staticClass:"allocate-info top-15-px"}),_c('div',{staticClass:"card-info-status"},[_c('div',{staticClass:"address-block"},[_c('AddressIcon',{staticClass:"address-icon"}),(_vm.UnitInfo.position)?_c('div',{staticClass:"address-txt text-primary"},[_c('b-link',{attrs:{"href":("http://maps.google.com/maps?q=loc:" + (_vm.UnitInfo.position.latitude) + "," + (_vm.UnitInfo.position.longitude)),"target":"_blank"}},[_vm._v(_vm._s(_vm.UnitInfo.position && _vm.UnitInfo.position.address))])],1):_vm._e()],1),(_vm.UnitInfo.position && _vm.UnitInfo.position.device_name)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html.top",value:({
              title: ("\n        <b class='no-wp-txt'>" + (_vm.$t(
          'UnitTrack.ReportedBy'
        )) + ":</b><span class='no-wp-txt'> " + (_vm.UnitInfo.position.device_name) + "  </span>\n        <b class='no-wp-txt'>" + (_vm.$t(
          'UnitTrack.ReportedAt'
        )) + ":</b><span class='no-wp-txt'> " + (_vm.getFormattedTime(
                _vm.UnitInfo.message_time
              )) + "  </span>"),
              customClass: 'tooltip-card'
            }),expression:"{\n              title: `\n        <b class='no-wp-txt'>${$t(\n          'UnitTrack.ReportedBy'\n        )}:</b><span class='no-wp-txt'> ${\n                UnitInfo.position.device_name\n              }  </span>\n        <b class='no-wp-txt'>${$t(\n          'UnitTrack.ReportedAt'\n        )}:</b><span class='no-wp-txt'> ${getFormattedTime(\n                UnitInfo.message_time\n              )}  </span>`,\n              customClass: 'tooltip-card'\n            }",modifiers:{"html":true,"top":true}}],staticClass:"info-address-tool curserPointer"},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"InfoIcon","size":"15"}})],1):_vm._e()])]),(_vm.UnitInfo.operator && _vm.UnitInfo.operator.name)?_c('b-card',{staticClass:"cust-card-unit second-card",attrs:{"no-body":""}},[_c('div',{staticClass:"card-data-ev"},[_c('b-avatar',{attrs:{"variant":"light-primary","pills":"","size":"60","src":_vm.UnitInfo.operator && _vm.UnitInfo.operator.icon}}),_c('div',{staticClass:"unit-name-ev-block"},[_c('div',{staticClass:"unit-name-ev-text"},[_vm._v(" "+_vm._s(_vm.UnitInfo.operator && _vm.UnitInfo.operator.name ? _vm.UnitInfo.operator.name : _vm.$t("unit.UnsignedDriver"))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.UnitInfo.operator && _vm.UnitInfo.operator.email),expression:"UnitInfo.operator && UnitInfo.operator.email"}],staticClass:"allow-text text-primary mt-10-mail"},[_c('b-link',{attrs:{"href":("mailto:" + (_vm.UnitInfo.operator.email)),"target":"_blank"}},[_c('MailIcon',{staticClass:"call-icon"}),_c('span',{staticClass:"ml-10"},[_vm._v(_vm._s(_vm.UnitInfo.operator.email))])],1)],1)])],1),_c('div',{staticClass:"allocate-info top-15-px"}),_c('div',{staticClass:"allocate-info wrap-allow-info"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.UnitInfo &&
              _vm.UnitInfo.operator &&
              _vm.UnitInfo.operator.shift_start_time
            ),expression:"\n              UnitInfo &&\n              UnitInfo.operator &&\n              UnitInfo.operator.shift_start_time\n            "}],staticClass:"allow-text shift-time mt-10-mail"},[_c('div',[_c('ShiftIcon',{staticClass:"shift-icon"}),_c('span',{staticClass:"shift-txt ml-10"},[_vm._v(_vm._s(_vm.$t("UnitTrack.Shift"))+" "+_vm._s(_vm.$t("UnitTrack.Time"))+" ")])],1),_c('span',{staticClass:"bold-time"},[_vm._v(_vm._s(("" + (_vm.UnitInfo.operator && _vm.UnitInfo.operator.shift_start_time ? ("" + (_vm.DateTimeConvert( _vm.UnitInfo.operator.shift_start_time, _vm.constants.DATE_TIME_FORMAT ))) : "")))+" "+_vm._s(_vm.UnitInfo.operator && _vm.UnitInfo.operator.shift_end_time ? _vm.$t("UnitTrack.To") : "")+" "+_vm._s(("" + (_vm.UnitInfo.operator && _vm.UnitInfo.operator.shift_end_time ? ("" + (_vm.DateTimeConvert( _vm.UnitInfo.operator.shift_end_time, _vm.constants.DATE_TIME_FORMAT ))) : ""))))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.UnitInfo.operator && _vm.UnitInfo.operator.mobile),expression:"UnitInfo.operator && UnitInfo.operator.mobile"}],staticClass:"allow-text shift-time mt-10-mail"},[_c('div',[_c('CallIcon',{staticClass:"call-icon"}),_c('span',{staticClass:"shift-txt ml-10"},[_vm._v(_vm._s(_vm.$t("UnitInfo.operator.ContactNo"))+":")])],1),_c('b-link',{attrs:{"href":("tel:" + (_vm.UnitInfo.operator.mobile)),"target":"_blank"}},[_c('span',{staticClass:"ml-10"},[_vm._v(_vm._s(_vm.UnitInfo.operator.mobile)+" ")])])],1)])]):_vm._e(),_vm._l((_vm.UnitInfo.devices),function(device){return _vm._l((device.data),function(data,index){return _c('span',{key:index + device.id,class:((data.type ? data.type : '') + " outer-span-blank")},[(data.display_card)?_c('span',{staticClass:"outer-span"},[_c('span',{staticClass:"inner-span",domProps:{"innerHTML":_vm._s(data.display_card)}}),(
                data.display_card &&
                device.message_time &&
                data.type != 'VIDEO_STREAM'
              )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html.top",value:({
                title: (_vm.title = "<b class='no-wp-txt'>" + (_vm.$t(
                  'UnitTrack.ReportedBy'
                )) + ":</b><span class='no-wp-txt'> " + (device.name) + "  </span> <b class='no-wp-txt'>" + (_vm.$t(
                  'UnitTrack.ReportedAt'
                )) + ":</b><span class='no-wp-txt'> " + (_vm.getFormattedTime(
                  device.message_time
                )) + "  </span>"),
                customClass: 'tooltip-card'
              }),expression:"{\n                title: (title = `<b class='no-wp-txt'>${$t(\n                  'UnitTrack.ReportedBy'\n                )}:</b><span class='no-wp-txt'> ${\n                  device.name\n                }  </span> <b class='no-wp-txt'>${$t(\n                  'UnitTrack.ReportedAt'\n                )}:</b><span class='no-wp-txt'> ${getFormattedTime(\n                  device.message_time\n                )}  </span>`),\n                customClass: 'tooltip-card'\n              }",modifiers:{"html":true,"top":true}}],staticClass:"info-icon-overlay"},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"InfoIcon","size":"15"}})],1):_vm._e(),(
                data.type == 'VIDEO_STREAM' && data.data && data.data.is_live
              )?_c('button',{staticClass:"on-card-video live-btn",style:(!['unit-history', 'unit-map'].includes(_vm.$route.name)
                  ? 'pointer-events: none !important'
                  : ''),on:{"click":function (event) { return _vm.openVideoStream(index, device); }}},[_vm._v(" "+_vm._s(_vm.$t("unit.Live"))+" "),_c('span',{staticClass:"live-doth"})]):_vm._e(),(
                data.type == 'VIDEO_STREAM' && data.data && !data.data.is_live
              )?_c('button',{staticClass:"on-card-video live-btn",style:(!['unit-history', 'unit-map'].includes(_vm.$route.name)
                  ? 'pointer-events: none !important'
                  : ''),on:{"click":function (event) { return _vm.openVideoStream(index, device); }}},[_vm._v(" "+_vm._s(_vm.$t("unit.Start"))+" "),_c('span',{staticClass:"live-doth"})]):_vm._e()]):_vm._e()])})})],2):_vm._e(),(
        _vm.UnitInfo &&
        _vm.UnitInfo.message_time &&
        !_vm.unitInfoLoader &&
        !_vm.isOnlyPlayBack
      )?_c('div',{staticClass:"last-sync"},[_c('LastSync'),(_vm.$route.name === 'unit-map')?_c('span',{staticClass:"ls-sync"},[_vm._v(" "+_vm._s(_vm.$t("unit.LastSync"))+" :")]):_vm._e(),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:({
          title: _vm.DateTimeConvert(
            _vm.UnitInfo.message_time,
            _vm.constants.DATE_TIME_FORMAT
          ),
          customClass: 'my-tooltip-class tooltip-card'
        }),expression:"{\n          title: DateTimeConvert(\n            UnitInfo.message_time,\n            constants.DATE_TIME_FORMAT\n          ),\n          customClass: 'my-tooltip-class tooltip-card'\n        }",modifiers:{"hover":true,"top":true}}],staticClass:"text-primary"},[_vm._v(_vm._s(_vm.getFormattedTime(_vm.UnitInfo.message_time)))])],1):_vm._e()]):_vm._e(),_c('b-modal',{attrs:{"id":"open-stream-popup","centered":"","hide-header":true,"no-close-on-esc":"","no-close-on-backdrop":"","modal-class":"no-header-modal player-modal"}},[_c('VideoStream',{attrs:{"options":_vm.options,"name":_vm.sensorName,"unitObj":_vm.unitObj,"deviceObj":_vm.deviceObj,"source":_vm.source,"getLiveBtnStatus":_vm.getLiveBtnStatus},on:{"closeVideoModal":_vm.closeVideoModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }