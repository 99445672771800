<template>
  <div class="list-scroll-trip" ref="scrollContainer" @scroll="handleScroll">
    <div class="table-responsive" v-if="showLoading == 0">
      <b-skeleton-table
        :rows="6"
        :columns="2"
        :table-props="{ bordered: true, striped: true }"
      />
    </div>
    <div v-if="showLoading == 1">
      <b-jumbotron :lead="$t('unit.NoTripTxt')"> </b-jumbotron>
    </div>
    <div v-if="showLoading == 2">
      <div
        v-for="(trip, index) in trips"
        :key="index"
        class="d-flex justify-content-between align-items-center b-card trip-ls-data"
      >
        <b-media no-body class="media-card">
          <b-form-checkbox
            :value="trip.trip_id"
            v-model="selectedCheckbox"
            :button-variant="trip.color"
            @change="
              (selected) => {
                selectedTrip(trip, selected, index);
              }
            "
            class="form-control-merge cursor-pointer"
          >
          </b-form-checkbox>
          <!-- <b-media-aside class="two-icon-show">
            <StartPoint class="size-22 trip-point" />
            <EndPoint class="size-22 trip-point" />
          </b-media-aside> -->
          <b-media-body
            class="my-auto mb-body trip-address-outer cursor-pointer"
            @click="clickOnTrip(trip, index)"
          >
            <div class="left-section">
              <h6 class="trip-address trip-line">
                <StartPoint class="size-22 trip-point" />
                <div class="trip-detail-outer">
                  <div class="trip-add-trim">
                    {{
                      trip.start_point.address
                        ? trip.start_point.address
                        : trip.start_point.latitude +
                          " , " +
                          trip.start_point.latitude
                    }}
                  </div>
                  <div class="start-end-date-time">
                    {{
                      DateTimeConvert(
                        trip.start_time,
                        constants.DATE_TIME_FORMAT
                      )
                    }}
                  </div>
                </div>
                <div class="color-line" :style="getColorLine(trip.color)"></div>
              </h6>
              <h6 class="trip-address" v-if="trip.is_live">
                <div class="live-trip">
                  <div class="icon-outer">
                    <IsLive class="live" />
                  </div>
                  <div class="trip-add-trim">Live</div>
                </div>
              </h6>
              <h6 class="trip-address" v-else>
                <EndPoint class="size-22 trip-point" />

                <div class="trip-detail-outer">
                  <div class="trip-add-trim">
                    {{
                      trip.end_point.address
                        ? trip.end_point.address
                        : trip.end_point.latitude +
                          " , " +
                          trip.end_point.latitude
                    }}
                  </div>
                  <div class="start-end-date-time">
                    {{
                      DateTimeConvert(trip.end_time, constants.DATE_TIME_FORMAT)
                    }}
                  </div>
                </div>
              </h6>
            </div>
            <div class="right-section">
              <div class="trip-info-detail">
                <DistanceIcon />
                <div class="trip-detail-reading">
                  {{ trip.distance ? trip.distance : "---" }}
                </div>
              </div>
              <div class="trip-info-detail">
                <PumpIcon />
                <div class="trip-detail-reading">
                  {{ trip.fuel ? trip.fuel : "---" }}
                </div>
              </div>
              <div class="trip-info-detail">
                <CarIcon />
                <div class="trip-detail-reading">
                  {{
                    trip.is_live ? "---" : trip.duration ? trip.duration : "---"
                  }}
                </div>
              </div>
            </div>
          </b-media-body>
        </b-media>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BMediaBody,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  BInputGroup,
  BFormGroup,
  BImg,
  BFormCheckbox,
  BMediaAside,
  VBTooltip,
  BCardBody,
  BSkeletonTable,
  BJumbotron,
  BContainer
} from "bootstrap-vue";

import UnitService from "@/libs/api/unit-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ImageFallBack from "vue-fall-back-image-directive";
import StartPoint from "@/assets/images/unit-icon/start-point.svg";
import * as moment from "moment";
import constants from "@/utils/constants";
import * as Utils from "@/libs/utils";
import EndPoint from "@/assets/images/unit-icon/end-point.svg";
import DistanceIcon from "@/assets/images/new-icon/distance-icon.svg";
import PumpIcon from "@/assets/images/new-icon/pump-icon.svg";
import CarIcon from "@/assets/images/new-icon/car-icon.svg";
import IsLive from "@/assets/images/new-icon/is-live.svg";
import SocketIOService from "@/libs/socket/sockets";
import store from "@/store";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BMediaBody,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BInputGroup,
    BFormGroup,
    BImg,
    BFormCheckbox,
    BMediaAside,
    ImageFallBack,
    VBTooltip,
    BCardBody,
    StartPoint,
    EndPoint,
    BSkeletonTable,
    BJumbotron,
    BContainer,
    PumpIcon,
    CarIcon,
    DistanceIcon,
    IsLive
  },
  data() {
    return {
      selected: "10",
      trips: [],
      showLoading: 1,
      totalTrips: 0,
      selectedCheckbox: [],
      currentPage: 1,
      sensorArray: [],
      unitTripsSelected: {},
      tripStore: [],
      unit: {},
      tripDetails: {},
      page_size: 10,
      page: 1
    };
  },

  props: ["playback", "unitDateFilter", "onCloseTrip"],
  setup() {},
  directives: {
    "b-tooltip": VBTooltip
  },

  watch: {
    unitDateFilter: {
      deep: true,
      handler(newOptions) {
        this.playback.clearMap();
        this.playback.moveToEnd();
        this.playback.recenterMap();
        this.page = 1;
        this.page_size = 10;
        this.getAllTrips(false);
      }
    },
    selectedUnit() {
      this.page = 1;
      this.page_size = 10;
      if (this.socket) {
        this.socket.disconnect();
        this.socket = null;
      }
      this.getAllTrips(false);
    }
  },

  mounted() {
    // this.getAllUnitList();
    if (this.unitDateFilter && this.unitDateFilter.selectedUnit) {
      this.getAllTrips(false);
    }
  },
  methods: {
    DateTimeConvert(date, format) {
      if (!date) {
        return "";
      }
      return this.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    },
    handleScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      const scrollOffset =
        scrollContainer.scrollHeight - scrollContainer.scrollTop - 1;
      const containerHeight = scrollContainer.clientHeight;
      if (scrollOffset > containerHeight) {
        if (this.totalTrips > this.page * this.page_size) {
          this.page++;
          this.getAllTrips(true);
        }
      }
    },
    async getAllTrips(isScroll) {
      try {
        if (
          !this.unitDateFilter ||
          !this.unitDateFilter.selectedUnit ||
          !this.unitDateFilter.account_id ||
          !this.unitDateFilter.start_time ||
          !this.unitDateFilter.end_time ||
          this.unitDateFilter.start_time === "Invalid date" ||
          this.unitDateFilter.end_time === "Invalid date"
        ) {
          store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
          return;
        }
        let filters;
        if (
          this.unitDateFilter &&
          this.unitDateFilter.start_time &&
          this.unitDateFilter.end_time &&
          (this.unitDateFilter.start_time !== "Invalid date" ||
            this.unitDateFilter.end_time !== "Invalid date")
        ) {
          let account = localStorage.getItem("USER_ACCOUNT_DETAILS");
          account = account ? JSON.parse(account) : null;
          account =
            account && account.settings ? account.settings : constants.SETTINGS;
          const timeZone = account.timezone;
          filters = [
            {
              field: "time",
              start_value: moment
                .tz(this.unitDateFilter.start_time, timeZone || "utc")
                .startOf("day")
                .utc()
                .format(),
              end_value: this.unitDateFilter.end_time,
              operator: "between"
            }
          ];
        }
        if (
          !this.unitDateFilter.selectedUnit ||
          !this.unitDateFilter.account_id
        ) {
          store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
          return;
        }
        if (!isScroll) {
          this.showLoading = 0;
          this.trips = [];
          this.selectedCheckbox = [];
          this.tripStore = [];
        }
        store.commit("mapLoader/SET_IS_LOADING", true);
        let response = await new UnitService().getAllTrips({
          unit_id: this.unitDateFilter.selectedUnit,
          account_id: this.unitDateFilter.account_id,
          filters,
          page: this.page,
          page_size: this.page_size
        });

        if (response && response.data) {
          this.showLoading = 2;
          store.commit("mapLoader/SET_IS_LOADING", false);
          let tripItems = response.data.trips && response.data.trips.list;
          tripItems = tripItems.map((e) => {
            e.color = Utils.randomDarkColor(Math.floor(Math.random() * 10));
            return e;
          });

          this.trips = isScroll ? [...this.trips, ...tripItems] : tripItems;

          this.unit = response.data.unit;
          this.totalTrips =
            (response.data.trips &&
              response.data.trips.pagination &&
              response.data.trips.pagination.total_records) ||
            0;
          store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
          if (this.totalTrips == 0) {
            this.showLoading = 1;
          }
        } else if (response && response.error && response.error.message) {
          store.commit("mapLoader/SET_IS_LOADING", false);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          this.showLoading = 1;
        }
      } catch (error) {
        this.showLoading = 1;
        store.commit("mapLoader/SET_IS_LOADING", false);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        // this.$router.push({ name: "error-404" });
      }
    },
    selectedTrip(trip, selected, index) {
      if (selected.includes(trip.trip_id)) {
        //   this.getTripDetails(trip.trip_id);
        this.getTripCooards(trip, index);
      } else {
        // this.tripStore = this.tripStore.filter(
        //   (e) => e.trip_id !== trip.trip_id
        // );
        this.playback.removeTrack({ id: trip.trip_id });
        this.playback.moveToEnd();
        this.playback.recenterMap();
        // this.onTripClick(this.tripStore, this.unit);
      }
    },
    clickOnTrip(trip, index) {
      const isSelected = this.selectedCheckbox.includes(trip.trip_id)
        ? true
        : false;
      if (isSelected) {
        this.selectedCheckbox = this.selectedCheckbox.filter(
          (e) => e !== trip.trip_id
        );
      } else {
        this.selectedCheckbox.push(trip.trip_id);
        this.$emit("onCloseTrip", false);
        this.playback.events.fire("pointClick", {
          message: {
            tripId: trip.trip_id,
            unitId: this.unit.id
          },
          is_live: trip.is_live,
          screen: "trip"
        });
      }
      this.selectedTrip(trip, this.selectedCheckbox, index);

      // if (!isSelected) {
      //   this.onClickUnitDetail({
      //     trip_id: trip.trip_id,
      //     unit_id: this.unitDateFilter.selectedUnit,
      //     is_clear: isSelected
      //   });
      // } else {
      //   this.onClickUnitDetail({
      //     is_clear: isSelected
      //   });
      // }
    },
    async getTripCooards(trip, index) {
      try {
        store.commit("mapLoader/SET_IS_LOADING", true);
        const response = await new UnitService().getTripCoordinates({
          unit_id: this.unitDateFilter.selectedUnit,
          trip_id: trip.trip_id,
          account_id:
            this.$route.query.account_id ||
            localStorage.getItem("USER_ACCOUNT_ID")
        });
        store.commit("mapLoader/SET_IS_LOADING", false);
        if (response && response.data && response.data.length) {
          if (this.$route.name !== "unit-trips") {
            return;
          }

          const tripCoordPoints = response.data;
          tripCoordPoints[0].event = {
            icon:
              this.getCurrentPath() + "/app/images/unit-icon/start-point.svg"
          };
          if (!trip.is_live) {
            tripCoordPoints[tripCoordPoints.length - 1].event = {
              icon:
                this.getCurrentPath() + "/app/images/unit-icon/end-point.svg"
            };
          }

          const unitInfo = {
            trip_id: trip.trip_id,
            id: trip.trip_id,
            unit_id: this.unit.id,
            name: this.unit.name,
            icon: this.unit.icon,
            screen: "trip",
            is_live: trip.is_live,
            color: trip.color,
            points: tripCoordPoints
          };

          this.playback.addTrack(unitInfo, tripCoordPoints);
          this.playback.moveToEnd();
          this.playback.recenterMap();
          if (trip.is_live) {
            this.subscribeUnitData(this.unit, true, trip);
          }
        } else if (response && response.error && response.error.message) {
          store.commit("mapLoader/SET_IS_LOADING", false);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (error) {
        store.commit("mapLoader/SET_IS_LOADING", false);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        // this.$router.push({ name: "error-404" });
      }
    },
    getColorLine(color) {
      return { "border-right": `2px dashed ${color}` };
    },
    async subscribeUnitData(unit, subscribe, trip) {
      if (!this.socket) {
        this.socket = new SocketIOService();
        await this.socket.setupSocketConnection();
      }
      if (subscribe && !unit.subscribed) {
        let totalDistance = 0;
        this.socket.subscribeEventWithFunction(
          `UM_${this.unitDateFilter.selectedUnit}`,
          (data) => {
            if (this.$route.name !== "unit-trips") {
              return;
            }
            let lastPositionData = data.device.data.find(
              (msg) => msg.type == "GPS"
            );
            if (
              lastPositionData &&
              lastPositionData.data &&
              lastPositionData.data.latitude &&
              lastPositionData.data.longitude
            ) {
              lastPositionData.data.distance =
                lastPositionData.data.distance / 1000;

              const isTrack = this.playback.getTrackPoint({
                id: trip.trip_id
              });
              let lastDistance = 0;
              if (isTrack) {
                lastDistance = isTrack.getTrackDistance();
              }
              const date = new Date(data.message_time);

              const track = {
                trip_id: trip.trip_id,
                id: trip.trip_id,
                unit_id: this.unit.id,
                screen: "trip",
                is_live: true,
                icon: unit.icon
              };
              track.points = {
                id: data.device.message_id,
                latitude: lastPositionData.data.latitude,
                longitude: lastPositionData.data.longitude,
                angle: lastPositionData.data.angle,
                speed: lastPositionData.data.gps_speed,
                distance: lastDistance + lastPositionData.data.distance,
                date: date.valueOf()
              };
              if (!isTrack) {
                this.playback.addTrack({ ...track, color: "blue" }, [
                  track.points,
                  track.points
                ]);
              } else {
                const isLiveUnit = this.$store.state.mapLoader.is_live_unit;
                this.playback.addTrackPoint(track, track.points, isLiveUnit);
              }
            }
          }
        );
      } else if (unit.subscribed) {
        unit.subscribed = false;
        this.socket.unsubscribeEventWithFunction(`UM_${unit.id}`);
      }
    }
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }
};
</script>

<style lang="scss">
.trip-line {
  position: relative;
  .color-line {
    position: absolute;
    top: 13px;
    width: 0px;
    left: 10px;
    height: calc(100% - 9px);
    z-index: 0;
    // border-right: 2px dashed red;
  }
  &.trip-address {
    padding-bottom: 12px;
  }
}
.live-trip {
  display: flex;
  width: 100%;
  .icon-outer {
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
  }
  .trip-add-trim {
    padding-left: 10px;
  }
}
.trip-ls-data {
  box-shadow: none;
  border: 1px solid var(--gray-605);
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 7px;
  padding: 15px;
  .size-22 {
    height: 22px;
    width: 22px;
    &:first-child {
      margin-bottom: 0px;
      margin-top: 5px;
    }
  }
  .trip-address-outer {
    display: flex;
    align-items: center;
    .trip-address {
      display: flex;
      line-height: 22px;
      margin-bottom: 0px;
      &:first-child {
        margin-bottom: 0px;
        margin-top: 0px;
      }
      .trip-point {
        position: relative;
        z-index: 2;
      }
    }
    .left-section {
      width: calc(72% - 20px);
      margin-right: 20px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 1px;
        height: 100%;
        z-index: 1;
        right: -10px;
        background: #eeecec;
      }
    }
    .right-section {
      min-width: 29%;
      .trip-info-detail {
        display: flex;
        align-items: center;
      }
      .trip-detail-reading {
        font-size: 12px;
        padding-left: 7px;
        padding-bottom: 3px;
      }
    }
    .trip-add-trim {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      font-size: 12px;
      line-height: 17px;
    }
    .start-end-date-time {
      font-size: 10px;
      line-height: 16px;
      font-weight: 500;
    }
    .trip-detail-outer {
      width: calc(100% - 33px);
    }
    .trip-point {
      margin-right: 10px;
    }
  }
  .two-icon-show {
    flex-direction: column;
    &.media-aside {
      .b-avatar {
        &:first-child {
          margin-bottom: 0px;
          margin-top: 5px;
        }
        .b-avatar-img {
          padding: 0px;
          img {
            object-fit: contain;
          }
        }
      }
    }
  }

  .b-avatar.badge-secondary {
    background-color: var(--gray-605);
    border-radius: 50% !important;
    margin-left: 6px;
  }
  .media-card {
    width: 100%;
    padding-top: 0px;
  }
  .trip-point {
    circle {
      fill: var(--primary);
    }
  }
}
.dark-layout {
  .trip-ls-data {
    .b-avatar.badge-secondary {
      background-color: var(--light);
    }
  }
}
.list-scroll-trip {
  min-height: calc(100vh - 380px);
  max-height: calc(100vh - 380px);
  overflow-y: auto;
  margin-top: 15px;
}
.form-group {
  margin-bottom: 1rem !important;
}
// @media screen and (max-width: 991px) {
//   .list-scroll-trip {
//     max-height: calc(100vh - 490px);
//     min-height: calc(100vh - 490px);
//   }
// }
@media screen and (max-width: 667px) {
  .list-scroll-trip {
    max-height: calc(100vh - 420px);
    min-height: calc(100vh - 420px);
  }
}
</style>
