<template id="slidemenu">
  <div class="menu" :class="open ? 'sm-info' : 'lg-info'">
    <div @click="toggleNav" :class="['toggle-button', { 'toggle-open': open }]">
      <MenuIcoIn v-if="open" />
      <MenuIcoOut v-else />
    </div>

    <nav
      :class="[
        'menu__nav',
        { 'menu__nav--open': open },
        { up: positionClass },
        { down: !positionClass }
      ]"
    >
      <div
        class="arrow bounce"
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
        @click="onTouchclick"
      >
        <feather-icon
          v-if="!positionClass"
          class="text-white iconpos"
          icon="ChevronsUpIcon"
          size="17"
        />
        <feather-icon
          v-if="positionClass"
          class="text-white iconpos"
          icon="ChevronsDownIcon"
          size="17"
        />
      </div>
      <UnitMenu v-model="selectedMenu" />
      <div class="unit-outer">
        <div
          class="search-outer padding-btm-0"
          v-if="
            checkAbility({
              action: constants.PERMISSIONS_ACTION.CONSUMER_ACCOUNT_READ,
              subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
            }) ||
            checkAbility({
              action: constants.PERMISSIONS_ACTION.ACCOUNT_READ,
              subject: constants.PERMISSIONS_MODEL.ACCOUNT
            })
          "
        >
          <AccountSearch
            class="unit-search-v2"
            v-model="selectedAccount"
            :selectedMenu="selectedMenu"
          />
        </div>

        <div class="search-outer" v-if="selectedMenu === 'units'">
          <search
            style="float: left"
            class="search-comp"
            v-model="searchFiled"
            :searchPlaceholder="$t('unit.SearchUnit')"
            :searchColumn="searchColumn"
            :operator="operator"
          ></search>
        </div>
        <div
          class="d-flex justify-content-between jus unit-filter"
          v-if="selectedMenu === 'units'"
        >
          <span class="mt-1"
            ><span
              @click="onSelectedUnit('all')"
              class="curserPointer align-items-center b-card unit-ls-data unit-filter__all"
              :class="
                unitStatusFiltering.includes('all')
                  ? 'unit-filter__all__selected unit-filter-icon'
                  : ''
              "
              v-b-tooltip.hover.top="$t('unit.All')"
              >{{ $t("unit.All") }}</span
            ></span
          >
          <span class=""
            ><b-img
              @click="onSelectedUnit(2)"
              src="/app/images/card-icon/car.svg"
              class="curserPointer align-items-center b-card unit-ls-data unit-filter__ls"
              :class="unitStatusFiltering.includes(2) ? 'border-primary' : ''"
              v-b-tooltip.hover.top="$t('unit.Running')"
          /></span>

          <span class=""
            ><b-img
              @click="onSelectedUnit(3)"
              src="/app/images/card-icon/parking.svg"
              class="curserPointer align-items-center b-card unit-ls-data unit-filter__ls"
              :class="unitStatusFiltering.includes(3) ? 'border-primary' : ''"
              v-b-tooltip.hover.top="$t('unit.Parked')"
          /></span>
          <span class=""
            ><b-img
              @click="onSelectedUnit(1)"
              src="/app/images/card-icon/new-idling.svg"
              class="curserPointer align-items-center b-card unit-ls-data unit-filter__ls"
              :class="unitStatusFiltering.includes(1) ? 'border-primary' : ''"
              v-b-tooltip.hover.top="$t('unit.Idling')"
          /></span>
          <span class=""
            ><b-img
              @click="onSelectedUnit(4)"
              src="/app/images/card-icon/ignition-off.svg"
              class="curserPointer align-items-center b-card unit-ls-data unit-filter__ls"
              :class="unitStatusFiltering.includes(4) ? 'border-primary' : ''"
              v-b-tooltip.hover.top="$t('unit.Inactive')"
          /></span>
        </div>

        <UnitSearch
          class="unit-search-v2 v-2-unit wh-unit-h"
          v-model="unitDateFilter.selectedUnit"
          :selectedMenu="selectedMenu"
          v-if="selectedMenu !== 'units'"
        />
        <UnitDatePicker
          v-model="selectedDate"
          :unit_id="unitDateFilter.selectedUnit"
          :selectedMenu="selectedMenu"
          :isMinDate="selectedMenu === 'services' ? true : false"
          v-if="!['units', 'history'].includes(selectedMenu)"
        />
        <UnitDatePickerCurrentDate
          v-model="selectedDate"
          :unit_id="unitDateFilter.selectedUnit"
          :selectedMenu="selectedMenu"
          v-if="selectedMenu === 'history'"
        />

        <UnitList
          v-show="selectedMenu === 'units'"
          :searchFiled="searchFiled"
          :unitStatusFiltering="unitStatusFiltering"
          :selectedMenu="selectedMenu"
          :selectedAccount="selectedAccount"
          :playback="playback"
          @closeMobileMenu="closeMobileMenu"
        />
        <UnitTrip
          v-if="selectedMenu === 'trips' && unitDateFilter.selectedUnit"
          :unitDateFilter="unitDateFilter"
          @onCloseTrip="onCloseTrip"
          :playback="playback"
        />
        <UnitServices
          :unitDateFilter="unitDateFilter"
          :selectedMenu="selectedMenu"
          v-else-if="selectedMenu === 'services' && unitDateFilter.selectedUnit"
        />
        <UnitVideo
          :unitDateFilter="unitDateFilter"
          :playVideoClick="playVideoClick"
          v-else-if="selectedMenu === 'videos' && unitDateFilter.selectedUnit"
        />
        <UnitEvent
          :unitDateFilter="unitDateFilter"
          :playback="playback"
          :selectedMenu="selectedMenu"
          v-else-if="selectedMenu === 'events' && unitDateFilter.selectedUnit"
        />
        <UnitActivity
          :unitDateFilter="unitDateFilter"
          :selectedMenu="selectedMenu"
          v-else-if="selectedMenu === 'activity' && unitDateFilter.selectedUnit"
        />
        <UnitHistory
          :unitDateFilter="unitDateFilter"
          :playback="playback"
          :selectedMenu="selectedMenu"
          v-else-if="selectedMenu === 'history' && unitDateFilter.selectedUnit"
        />
      </div>
    </nav>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BMediaBody,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  BInputGroup,
  BFormGroup,
  BImg,
  BFormCheckbox,
  BMediaAside,
  VBTooltip,
  BCardBody,
  BSkeletonTable,
  BButton
} from "bootstrap-vue";
import constants from "@/utils/constants";
import UnitList from "@/views/Unit/UnitTracking/UnitsList.vue";
import UnitTrip from "@/views/Unit/UnitTracking/UnitTrip.vue";
import UnitMenu from "@/views/Unit/UnitTracking/shared/UnitMenu.vue";
import UnitSearch from "@/views/Unit/UnitTracking/shared/UnitSearch.vue";
import AccountSearch from "@/views/Unit/UnitTracking/shared/AccountSearch.vue";
import UnitVideo from "@/views/Unit/UnitTracking/UnitVideo.vue";
import UnitEvent from "@/views/Unit/UnitTracking/UnitEvent.vue";
import UnitActivity from "@/views/Unit/UnitTracking/UnitActivity.vue";
import UnitHistory from "@/views/Unit/UnitTracking/UnitHistory.vue";
import UnitServices from "@/views/Unit/UnitTracking/UnitServices.vue";
import MenuIcoIn from "@/assets/images/unit-icon/side-bar-toggle-out.svg";
import MenuIcoOut from "@/assets/images/unit-icon/side-bar-toggle-in.svg";
import UnitDatePicker from "@/views/Unit/UnitTracking/shared/UnitDatePicker.vue";
import store from "@/store";
import UnitDatePickerCurrentDate from "@/views/Unit/UnitTracking/shared/UnitDatePickerCurrentDate.vue";
import Search from "@/views/Common/search.vue";
import * as moment from "moment";

export default {
  components: {
    MenuIcoIn,
    MenuIcoOut,
    UnitMenu,
    UnitList,
    UnitSearch,
    UnitDatePicker,
    UnitTrip,
    UnitVideo,
    UnitEvent,
    UnitActivity,
    UnitServices,
    Search,
    AccountSearch,
    UnitHistory,
    UnitDatePickerCurrentDate,
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BMediaBody,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BInputGroup,
    BFormGroup,
    BImg,
    BFormCheckbox,
    BMediaAside,
    VBTooltip,
    BCardBody,
    BSkeletonTable,
    BButton
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      touchStartY: 0,
      open: true,
      selectedMenu: "units",
      unitStatusFiltering: [],
      // selectedDate: {
      //   startDate: this.$route.query.start_time
      //     ? moment(decodeURIComponent(this.$route.query.start_time))
      //         .startOf("day")
      //         .format()
      //     : moment().startOf("day").format(),
      //   endDate: this.$route.query.end_time
      //     ? moment(decodeURIComponent(this.$route.query.end_time))
      //         .endOf("day")
      //         .format()
      //     : moment().endOf("day").format()
      // },
      selectedDate: {
        startDate: this.getCurrentTime(),
        endDate: this.getCurrentTime()
      },

      selectedAccount: "",
      searchColumn: ["name"],
      searchFiled: {},
      operator: "ilike",
      isLastSelectedUnit: "",
      unitDateFilter: {
        start_time: this.$route.query.start_time
          ? moment(decodeURIComponent(this.$route.query.start_time))
              .startOf("day")
              .format()
          : moment().startOf("day").format(),
        end_time: this.$route.query.end_time
          ? moment(decodeURIComponent(this.$route.query.end_time))
              .endOf("day")
              .format()
          : moment().endOf("day").format(),
        selectedUnit: "",
        account_id: ""
      },

      positionClass: false,
      selectedTrip: {}
    };
  },
  mounted() {
    this.updateSelectedActionMenu();

    let start_time = this.$route.query.start_time || new Date();
    let end_time = this.$route.query.end_time || new Date();
    if (this.$route.query.start_time) {
      this.selectedDate = {
        startDate: this.$route.query.start_time
          ? this.convertDatePerTimezone(
              decodeURIComponent(this.$route.query.start_time)
            )
          : this.getCurrentTime(),
        endDate: this.$route.query.end_time
          ? this.convertDatePerTimezone(
              decodeURIComponent(this.$route.query.end_time)
            )
          : new Date()
      };

      this.unitDateFilter = {
        start_time: this.$route.query.start_time
          ? this.convertDatePerTimezone(
              decodeURIComponent(this.$route.query.start_time)
            )
          : this.getCurrentTime(),
        end_time: this.$route.query.end_time
          ? this.convertDatePerTimezone(
              decodeURIComponent(this.$route.query.end_time)
            )
          : new Date(),
        selectedUnit: "",
        account_id: ""
      };
    }
  },

  watch: {
    selectedMenu(val) {
      this.playback.clearMap();
      this.playback.moveToEnd();
      this.playback.recenterMap();

      const { account_id, id } = this.$route.query || {};
      const userAccountId =
        account_id || localStorage.getItem("USER_ACCOUNT_ID");

      const routeMappings = {
        events: "unit-events",
        activity: "unit-activity",
        videos: "unit-videos",
        trips: "unit-trips",
        units: "unit-map",
        history: "unit-history",
        services: "unit-services"
      };

      const routeName = routeMappings[val];

      if (val && routeName !== "unit-map") {
        const isDifferentRoute = this.$route.name !== routeName;

        // this.unitDateFilter = {
        //   start_time: moment().startOf("day").format(),
        //   end_time: moment().endOf("day").format(),
        //   selectedUnit: id || "",
        //   account_id: userAccountId
        // };
      } else {
        this.unitDateFilter = {
          start_time: this.getCurrentTime(),
          end_time: this.getCurrentTime(),
          selectedUnit: ""
        };
        store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
      }

      // setTimeout(() => {
      if (!routeName || routeName === "unit-map") {
        let query = {
          account_id:
            this.$route.query.account_id || localStorage.getItem("ACCOUNT_id")
        };
        if (this.$route.query.id) {
          query.id = this.$route.query.id;
        }
        if (this.$route.query.filter) {
          query.filter = this.$route.query.filter;
        }

        this.$router
          .push({
            name: "unit-map",
            query
          })
          .catch((err) => {});
      } else {
        this.$router
          .push({ name: routeName, query: this.$route.query })
          .catch((err) => {
            this.$router
              .push({ name: routeName, query: this.$route.query })
              .catch((err) => {});
          });
      }
      // }, 500);
    },
    selectedAccount(val) {
      this.playback.clearMap();
      this.playback.moveToEnd();
      this.playback.recenterMap();
    },
    $route(to, from) {
      // setTimeout(() => {
      this.updateSelectedActionMenu();

      this.unitDateFilter.selectedUnit = this.$route.query.id
        ? this.$route.query.id
        : "";

      this.unitDateFilter.account_id = this.$route.query.account_id
        ? this.$route.query.account_id
        : localStorage.getItem("USER_ACCOUNT_ID");

      // }, 500);
    },
    selectedDate: {
      deep: true,
      handler(newOptions) {
        this.start_time = this.selectedDate && this.selectedDate.startDate;
        this.end_time =
          (this.selectedDate && this.selectedDate.endDate) ||
          this.getCurrentTime();
        this.unitDateFilter.start_time = this.start_time;
        this.unitDateFilter.end_time = this.end_time;
        this.unitDateFilter.account_id = this.$route.query.account_id
          ? this.$route.query.account_id
          : localStorage.getItem("USER_ACCOUNT_ID");
        if (this.selectedMenu === "history" && !this.positionClass) {
          //this.onTouchclick();
        }
        if (
          !this.unitDateFilter.selectedUnit &&
          this.selectedMenu !== "units"
        ) {
          store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
        }
        this.playback.toggleRecenter(true);
      }
    },
    closeMenu(val) {
      // this.positionClass = !this.positionClass;
    },
    "$store.state.mapLoader.is_popup_show"(val) {
      if (val) {
        this.positionClass = true;
      } else {
        this.positionClass = false;
      }
    }
  },

  props: ["openMapClass", "playback", "closeMenu"],
  computed: {},
  methods: {
    onSelectedUnit(id) {
      this.playback.clearMap();
      this.playback.moveToEnd();
      this.playback.recenterMap();
      if (this.unitStatusFiltering.includes(id)) {
        this.unitStatusFiltering = this.unitStatusFiltering.filter(
          (item) => item !== id
        );
      } else {
        if (id === "all") {
          this.unitStatusFiltering = [id];
        } else {
          this.unitStatusFiltering = [...this.unitStatusFiltering, id].filter(
            (id) => id !== "all"
          );
        }
      }
    },
    closeMobileMenu(val) {
      this.positionClass = val;
    },
    onTouchStart(event) {
      this.touchStartY = event.touches[0].clientY;
    },
    onTouchMove(event) {
      const touchCurrentY = event.touches[0].clientY;
      const deltaY = this.touchStartY - touchCurrentY;
      if (deltaY > 100) {
        this.handleSwipeUp();
      } else {
        this.handleSwipeDown();
      }
    },
    onTouchEnd() {
      this.touchStartY = 0;
    },
    handleSwipeUp() {
      store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
    },
    handleSwipeDown() {
      store.commit("mapLoader/SET_IS_POPUP_SHOW", false);
    },
    onTouchclick() {
      store.commit("mapLoader/SET_IS_POPUP_SHOW", !this.positionClass);
      this.positionClass = !this.positionClass;
    },
    onCloseTrip() {
      store.commit("mapLoader/SET_IS_POPUP_SHOW", false);
    },
    toggleNav: function () {
      this.open = !this.open;
      this.$emit("toggle", this.open);
      this.openMapClass(this.open);
    },

    playVideoClick(click) {
      if (click) {
        store.commit("mapLoader/SET_IS_POPUP_SHOW", !this.positionClass);
      }
    },

    updateSelectedActionMenu() {
      const routeToMenuMapping = {
        "unit-events": "events",
        "unit-activity": "activity",
        "unit-videos": "videos",
        "unit-trips": "trips",
        "unit-map": "units",
        "unit-history": "history",
        "unit-services": "services"
      };
      // setTimeout(() => {
      const routeName = this.$route.name;
      this.selectedMenu = routeToMenuMapping[routeName] || "units";
      // if (this.selectedMenu === "history") {
      if (this.selectedMenu === "history") {
      } else {
        // if (!this.positionClass) {
        //   this.onTouchclick();
        // }
      }
      this.selectedAccount = this.$route.query.account_id
        ? this.$route.query.account_id
        : localStorage.getItem("USER_ACCOUNT_ID");
      // }
      // }, 300);

      // this.playback.clearMap();
    }
  }
};
</script>
<style lang="scss">
.unit-outer {
  @media screen and (min-width: 760px) {
    .daterangepicker.openscenter {
      left: 0% !important;
    }
    .daterangepicker {
      min-width: auto !important;
      .calendars {
        max-height: calc(100vh - 390px);
        overflow-y: auto;
        flex-wrap: nowrap !important;
      }
    }
    .daterangepicker .calendars-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }
}
</style>
<style lang="scss">
.menu__nav {
  position: absolute;
  top: 0;
  right: -400px;
  height: 97%;
  width: 400px;
  // overflow-y: scroll;
  transition: all 0.3s;
  z-index: 11;
  min-height: calc(100vh - 107px);
  border-radius: 0px 0px 0px 8px;
  background: var(--white);
  box-shadow: 0px 0px 15px 0px rgba(34, 41, 47, 0.05);
  padding: 18px;
}
.menu__nav--open {
  // overflow: hidden; remove hidden because cut bounce button on map screen and calender hidden.
  right: 0;
}

.toggle-button {
  background-color: var(--primary);
  width: 50px;
  padding: 8px 10px;
  border-radius: 6px 0px 0px 6px;
  position: absolute;
  z-index: 991;
  right: 0px;
  top: 2px;
  transition: all 0.3s;
  cursor: pointer;
  &.toggle-open {
    right: 400px;
  }
}
.search-outer {
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  .search-filter {
    width: 100%;
    .pr-1 {
      display: none;
    }
    .form-group {
      width: 100%;
    }
  }
}
.unit-filter__all {
  padding: 14px 22px;
  color: var(--dark46);
}
.unit-filter__all__selected {
  color: var(--white);
}
.unit-filter__ls {
  padding: 5px 15px;
}
.unit-filter-icon {
  background-color: var(--primary);
}
.dark-layout {
  .unit-filter__all {
    border: 1px solid #404656;
    color: var(--white);
  }
  .unit-filter__ls {
    border: 1px solid #404656;
  }
}
// .lg-info ~ .outer-info {
//   width: calc(100% - 40px);
//   transition: all 0.3s;
//   max-width: 14000px;
// }
@media screen and (max-width: 667px) {
  .toggle-button {
    display: none;
  }
  .menu__nav {
    right: 0;
    left: 0;
    top: 82%;
    width: 100%;
    max-width: 100%;
    position: fixed;
    padding: 8px 10px;
    margin: 0 auto;
    // z-index: 9998; updated because menu overlap on active session popup
    z-index: 999;
    border-radius: 8px 8px 8px 8px;
    transition: all 0.5s;
  }
  .bounce {
    width: 35px;
    height: 35px;
    background: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: calc(50% - 15px);
    margin-top: -35px;
    border-radius: 50%;
    position: relative;
    .iconpos {
      -moz-animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      animation: bounce 2s infinite;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: inherit;
      border-radius: 50%;
      z-index: -1;
      animation: ripple 1.5s ease-out 2 forwards;
    }
  }
  @keyframes ripple {
    from {
      opacity: 1;
      transform: scale(0);
    }
    to {
      opacity: 0;
      transform: scale(2);
    }
  }
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  .menu__nav--open {
    top: 10%;
  }
  .menu__nav.up {
    top: 15%;
  }
  .menu__nav.down {
    top: calc(100% - 104px);
  }
}
.dark-layout {
  .menu__nav {
    background-color: var(--dark46);
  }
  .unit-search-v2 {
    &.v-2-unit {
      // margin-bottom: 12px;
    }
    .vs__dropdown-menu {
      background: var(--unit-bg-dark);
      border-radius: 4px;
    }
  }
}
.search-outer.padding-btm-0 {
  margin-bottom: 0px;
}
@media screen and (min-width: 767px) and (max-width: 1200px) {
  .menu__nav,
  .toggle-button {
    z-index: 98;
  }
}
.wh-unit-h {
  .v-select.vs--single .vs__selected {
    margin-top: 5px !important;
  }
}
</style>
<style lang="scss">
.new-date-picker-25 {
  .daterangepicker.ltr.show-calendar.single.openscenter.linked {
    left: 0 !important;
    transform: translate(0%) !important;
  }
}
</style>
